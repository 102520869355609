<template>
    <div :class="['CEESAR-MenuItem', isRoute? 'CEESAR-MenuItem-Routed' : '']" v-on:click="OnClick">

        <MaterialDesign-Icon v-if="icon != undefined" :icon="icon" size="24"/>

        <div class="CEESAR-MenuItemLabel">
            {{fullLabel}}
        </div>
        
    </div>
</template>

<script>
export default {
    name: "CEESAR-MenuItem",
    props:{
        label: {type: String, required: true},
        route: {type: String, default: undefined},
        url: {type: String, default: undefined},
        glyph: {type: String, default: undefined},
        emoji: {type: String, default: undefined},
        icon: {type: String, default: undefined}
    },
    computed:{
        fullLabel(){
            let label = "";
            if(this.emoji != undefined)
            {
                label = String.fromCodePoint(this.emoji) + " ";
            }
            label += this.label;
            return label;
        },
        isRoute(){
            return this.getIsRoute();
        }
    },
    methods:
    {
        getIsRoute(){
            if(this.$route == undefined) return false;
            if (this.route == undefined) return false;

            let currentPath = this.$router.currentRoute.path;
            let begin = currentPath.substring(0, this.route.length) + '/';

            // console.log(begin, this.route + '/');
            if(begin == this.route + '/') return true;
            // console.log(currentPath, this.route);
            if (currentPath == this.route) return true;

            return false;
        },
        OnClick()
        {
            if(this.route != undefined)
            {
                if(this.getIsRoute()) return;
                this.$router.push(this.route);
            }
            else if(this.url != undefined)
            {
                window.location = this.url;
            }
            else
            {
                this.$emit("click");
            }
        }
    }
}
</script>

<style lang="less">

</style>